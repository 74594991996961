import React from "react"
import styled from "styled-components"
import BackgroundWrapper from "../components/wrapper-background"
import ContentWrapper from "../components/wrapper-content"
import CheckoutSample from "../components/checkout-sample-pack"
import theme from "../theme"

const Content = styled(ContentWrapper)`
  color: ${theme.color.white}; 
  max-width: 620px;
`

const PanelSample = () => (

  <BackgroundWrapper className="backgroundBlue">

    <Content>

      <header>
        <h1>Try our beans</h1>
      </header>

      <p>We know bulk buying can be daunting so why not try our 60 gram sample pack and taste how good our Single Origin Nariño coffee beans really are.</p>

      <h2>Buy a sample pack - £2</h2>

      <CheckoutSample />

    </Content>

  </BackgroundWrapper>
  
)

export default PanelSample
