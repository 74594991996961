import React from "react"
import styled from "styled-components"
import BackgroundWrapper from "../components/wrapper-background"
import ContentWrapper from "../components/wrapper-content"
import ImageChivaBeans from "../components/img-chiva-beans"
import CheckoutBeans from "../components/checkout-beans"

const Content = styled(ContentWrapper)`
  max-width: 640px;
`

const PanelBeans = () => (

  <BackgroundWrapper className="backgroundWhite">

    <Content>

      <ImageChivaBeans />

      <h1>One great bean at one great price</h1>

      <p>Coffeeshop or not, you buy our single origin Colombian coffee beans in bulk, at weights that are worthwhile for everyone (you, the farmers, us, the planet)</p>

      <h2>Buy our beans - £20 per kilo</h2>

      <CheckoutBeans />

    </Content>

  </BackgroundWrapper>
  
)

export default PanelBeans
