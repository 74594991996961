import React from "react"
import styled from "styled-components"
import theme from "../theme"

const IncrementWrapper = styled.div`
  display: flex;
  align-items: center;
  margin-right: 10px;
`

const ContentWrapper = styled.div`
  display: flex;
  justify-content: center;
`

const IncrementCounter = styled.div`
  background-color: ${theme.color.white}; 
  color: ${theme.color.black}; 
  padding: 12px;
`

const IncrementButton = styled.button`
  background-color: ${theme.color.white}; 
  color: ${theme.color.black};
  padding-left: 10px;
  padding-right: 10px;
`

const CheckoutBeans = class extends React.Component {

  state = { count: 2 }

  handleIncrement = () => {
    this.setState({ count: this.state.count + 1 })
  }
  
  handleDecrement = () => {
    if(this.state.count > 2){
      this.setState({ count: this.state.count - 1 })
    }
  }

  // Initialise Stripe.js with your publishable key.
  // You can find your key in the Dashboard:
  // https://dashboard.stripe.com/account/apikeys
  componentDidMount() {
    this.stripe = window.Stripe("pk_live_POgDN0PTCmbgyy5a7LkBbFJ700iLgfqunG")
  }

  async redirectToCheckout(event) {
    event.preventDefault()
    const { error } = await this.stripe.redirectToCheckout({
      items: [{ sku: "sku_GNPu62pbWXtt2q", quantity: this.state.count }],
      successUrl: `https://chivacoffee.com/thank-you`,
      cancelUrl: `https://chivacoffee.com`,
      billingAddressCollection: 'required',
    })

    if (error) {
      console.warn("Error:", error)
    }
  }

  render() {
    return (

      <ContentWrapper>

        <IncrementWrapper>
        
          <IncrementButton onClick={this.handleDecrement}>-</IncrementButton>

          <IncrementCounter>{this.state.count}kg</IncrementCounter>

          <IncrementButton onClick={this.handleIncrement}>+</IncrementButton>

        </IncrementWrapper>

        <button onClick={event => this.redirectToCheckout(event)}>Buy Beans</button>

      </ContentWrapper>
    )
  }
}

export default CheckoutBeans