import React from "react"

const CheckoutSample = class extends React.Component {

  // Initialise Stripe.js with your publishable key.
  // You can find your key in the Dashboard:
  // https://dashboard.stripe.com/account/apikeys
  componentDidMount() {
    this.stripe = window.Stripe("pk_live_POgDN0PTCmbgyy5a7LkBbFJ700iLgfqunG")
  }

  async redirectToCheckout(event) {
    event.preventDefault()
    const { error } = await this.stripe.redirectToCheckout({
      items: [{ sku: "sku_GNPwyVYjsDuui7", quantity: 1 }],
      successUrl: `https://chivacoffee.com/thank-you`,
      cancelUrl: `https://chivacoffee.com`,
      billingAddressCollection: 'required',
    })

    if (error) {
      console.warn("Error:", error)
    }
  }

  render() {
    return (

      <button
        onClick={event => this.redirectToCheckout(event)}
        >
        Buy Chiva Sample Pack
      </button>

    )
  }
}

export default CheckoutSample