import React from "react"
import styled from "styled-components"
import Layout from "../components/layout"
import SEO from "../components/seo"
import PanelSample from "../components/panel-sample"
import PanelBeans from "../components/panel-beans"
import Header from "../components/header"
import ContentWrapper from "../components/wrapper-content"
import BackgroundWrapper from "../components/wrapper-background"
import { Link } from "gatsby"

const HeaderWrap = styled.header`
  max-width: 450px;
  margin: auto;
`

const IndexPage = (  ) => (

  <Layout>
    
    <SEO 
      title="Great Colombian coffee isn’t complicated"
      description="At Chiva we sell one great bean at one great price in one fair way" />

    <Header />

    <PanelBeans />

    <BackgroundWrapper className="backgroundBrownPattern">

      <ContentWrapper>
        <HeaderWrap>
          <h1>Great Colombian coffee isn’t complicated</h1>
        </HeaderWrap>
        <p>Forget endless choice, unfair business models and barista-babble. At Chiva we sell one great bean at one great price in one fair way.</p>
        <p>In the mountainous region of Nariño, Colombia, where it’s sunny but not too hot, the perfect coffee grows.</p>
        <p>A bean so unique, and so tasty, it’s got Protected Designation of Origin. It’s the champagne of coffee.</p>
        <Link className="button" to="/about-chiva">Read more about our beans</Link>
      </ContentWrapper>

    </BackgroundWrapper>

    <PanelSample />

  </Layout>
)

export default IndexPage
